@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  overflow-x: hidden;
}

.df-ui-controls {
  font-family: "PT Sans", sans-serif;
}

.item-viewer-container {
  width: 100vw;
  height: 100vh;
  position: inherit;

  .item-logo {
    padding: 80px 180px 20px;
    display: flex;
    justify-content: flex-end;
    min-height: 60px;

    img {
      max-height: 80px;
      min-height: 60px;
    }

    @media screen and (max-width: 425px) {
      padding: 40px;
    }
  }

  .item-content {
    max-height: calc(100vh - 200px);
    padding: 0 180px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    // align-items: center;

    @media screen and (max-width: 425px) {
      padding: 16px;
    }

    > * {
      box-shadow: 2.8px 2.2px rgba(0, 0, 0, 0.03),
        0 6.7px 5.3px rgba(0, 0, 0, 0.05), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.07), 0 41.8px 33.4px rgba(0, 0, 0, 0.09),
        0 100px 80px rgba(0, 0, 0, 0.1);
    }

    .item-full {
      width: 100%;
      height: 100%;
    }

    .item-docx {
      width: 100%;
      height: calc(100vh - 200px);
    }

    .item-img--container {
      max-width: 100%;
      max-height: 100%;
      box-shadow: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .item-img--content {
        border-radius: 16px;
        overflow: hidden;
        margin: auto;
        object-fit: contain;
        max-height: 100%;
        box-shadow: 2.8px 2.2px rgba(0, 0, 0, 0.03),
          0 6.7px 5.3px rgba(0, 0, 0, 0.05), 0 12.5px 10px rgba(0, 0, 0, 0.06),
          0 22.3px 17.9px rgba(0, 0, 0, 0.07),
          0 41.8px 33.4px rgba(0, 0, 0, 0.09), 0 100px 80px rgba(0, 0, 0, 0.1);
      }
    }

    .item-audio-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;

      audio {
        width: 500px;
      }
    }

    .item-video-container {
      background-color: transparent;
      max-height: 100%;
    }

    .item-html-container {
      background-color: #fff;
      width: 100%;
      height: 100%;
    }

    ._df_book {
      height: 100% !important;

      .df-3dcanvas {
        height: 100% !important;
      }
    }
  }

  .pg-viewer-wrapper {
    overflow-y: hidden;
  }

  .loading-wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .brand-box {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: white;
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    width: 100%;
    overflow: hidden;
    transform-origin: 0;
    z-index: -1;

    .custom-background {
      position: absolute;
      width: 100%;
      height: 120px;
      top: -60px;
      transform: skewY(-16deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.9;
    }

    .triangle1 {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: white;
      font-size: 36px;
      font-weight: 600;
      position: absolute;
      width: 100%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.8;
    }

    .triangle2 {
      position: absolute;
      height: 80px;
      right: 120px;
      bottom: 0px;
      left: 0%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.5;
      background: white;
    }

    .triangle3 {
      position: absolute;
      height: 100px;
      right: 40px;
      bottom: calc(100% * -0.06);
      left: 50%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.4;
      background: #ddd;
    }

    @media screen and (min-width: 1096px) {
      .triangle3 {
        right: 0;
        bottom: 0;
      }
    }

    @media screen and (max-width: 768px) {
      .triangle3 {
        right: 20px;
        bottom: -30px;
        height: 90px;
      }
    }

    @media screen and (max-width: 425px) {
      flex-direction: column;
      height: 500px;

      .triangle3 {
        right: 0;
        bottom: 60px;
        transform: skewY(-24deg);
        height: 160px;
        background: #fff;
      }

      .triangle1 {
        transform: skewY(-24deg);
      }

      .triangle2 {
        display: none;
      }

      .company-logo {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  &.pdf {
    .item-logo {
      position: absolute;
      right: 0;
      top: 0;
    }

    ._df_book {
      height: 100% !important;

      &.df-container  {
        background-color: transparent !important;
      }

      .df-3dcanvas {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
