.csv-render-container {
  background-color: #fff;
  height: 100%;
  overflow: auto;

  table,
  thead,
  th,
  td {
    border: 1px solid #ddd;
    border-collapse: collapse;
  }

  .csv-header {
    background: #f9f9f9;
    min-height: 36px;

    .header-csv-cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .csv-body {
    .csv-row {
      min-height: 36px;

      .csv-cell {
        padding: 8px 16px;
        max-height: 60px;

        .csv-cell-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          &.first-cell {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
