.password-check-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;

  .brand-box {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: white;
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    width: 100%;
    overflow: hidden;
    transform-origin: 0;
    filter: blur(2px);

    .custom-background {
      position: absolute;
      width: 100%;
      height: 120px;
      top: -60px;
      transform: skewY(-16deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.9;
    }

    .triangle1 {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: white;
      font-size: 36px;
      font-weight: 600;
      position: absolute;
      width: 100%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.8;
    }

    .triangle2 {
      position: absolute;
      height: 80px;
      right: 120px;
      bottom: 0px;
      left: 0%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.5;
      background: white;
    }

    .triangle3 {
      position: absolute;
      height: 100px;
      right: 40px;
      bottom: calc(100% * -0.06);
      left: 50%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.4;
      background: #ddd;
    }

    @media screen and (min-width: 1096px) {
      .triangle3 {
        right: 0;
        bottom: 0;
      }
    }

    @media screen and (max-width: 768px) {
      .triangle3 {
        right: 20px;
        bottom: -30px;
        height: 90px;
      }
    }

    @media screen and (max-width: 425px) {
      flex-direction: column;
      height: 500px;

      .triangle3 {
        right: 0;
        bottom: 60px;
        transform: skewY(-24deg);
        height: 160px;
        background: #fff;
      }

      .triangle1 {
        transform: skewY(-24deg);
      }

      .triangle2 {
        display: none;
      }

      .company-logo {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  .site-content-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    padding-top: 40px;
    width: calc(100% - 160px);
    flex-shrink: 0;
    filter: blur(2px);

    .company-logo {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-top: 40px;
      margin-bottom: 18px;
      height: 80px;

      .logo-box {
        width: 160px;
        height: 60px;
        background-color: #e2e2e2;
      }

      @media screen and (max-width: 425px) {
        margin-top: 40px;
        justify-content: center;
      }
    }

    .title-section {
      height: 100px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .avatar-box {
        position: relative;
        top: 0;
        left: 0;

        .avatar-circle {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          background-color: rgb(20, 48, 69);
        }
      }

      .site-title {
        margin-right: 0;
        text-align: center;
        margin-bottom: 10px;
      }

      .title-box {
        width: calc(100% - 100px);
        height: 80px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        margin-left: -20px;
      }
    }
  
    @media screen and (min-width: 320px) {
      .collections-container {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        padding: 20px 0px 0px 20px;
        padding-right: 0px;
        padding-bottom: 80px;
        border-radius: 4px;
        background-color: #eeeeee;
        margin-bottom: 80px;
        margin-top: 20px;
        min-height: calc(100vh - 400px);
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
          0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
          0 22.3px 17.9px rgba(0, 0, 0, 0.072),
          0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);

        .collection-card {
          flex: 0 1 calc(100% - 1em);
        }

        @media screen and (max-width: 425px) {
          width: 220px !important;
        }
      }
    }

    @media screen and (min-width: 52em) {
      .collection-card {
        flex: 0 1 calc(25% - 1em);
      }
    }

    @media screen and (min-width: 60em) {
      .collection-card {
        flex: 0 1 calc(25% - 1em);
      }
    }

    @media screen and (max-width: 425px) {
      align-items: center;
      max-width: calc(100% - 100px);

      .title-section {
        .title-box {
          margin-top: -5px;
          flex-direction: column;
          width: calc(100% - 20px);
          padding: 10px;
          height: auto;
          margin-left: 0px;
        }
      }
    }
  }

  .password-input-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .password-form {
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
      display: flex;
      flex-direction: column;
      width: 320px;

      .form-title {
        height: 40px;
        background-color: #3fc2cd;
      }

      .form-content {
        padding: 40px;
        font-family: "PT Sans", sans-serif;
        gap: 16px;
        display: flex;
        flex-direction: column;

        input {
          height: 32px;
          padding: 0px 8px;
        }

        .input-label {
          color: #3fc2cd;
        }

        .error-message {
          margin-top: 4px;
          margin-bottom: 4px;
          font-size: 12px;
          color: #dc3545;
        }

        .form-actions {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;

          .form-button {
            text-transform: uppercase;
            width: 80px;
            height: 28px;
            border-radius: 4px;
            color: #dc3545;
            border: 1px solid #dc3545;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: #dc3545;
              color: #fff;
            }
          }
        }
      }
    }
  }
}