// override for Flipbook
.df-ui-controls {
  font-family: "PT Sans", sans-serif;
}

.pdf-viewer-container {
  width: 100vw;
  height: 100vh;
  position: inherit;


  .loading-wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .brand-box {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: white;
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    width: 100%;
    overflow: hidden;
    transform-origin: 0;

    .custom-background {
      position: absolute;
      width: 100%;
      height: 120px;
      top: -60px;
      transform: skewY(-16deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.9;
    }

    .triangle1 {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: white;
      font-size: 36px;
      font-weight: 600;
      position: absolute;
      width: 100%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.8;
    }

    .triangle2 {
      position: absolute;
      height: 80px;
      right: 120px;
      bottom: 0px;
      left: 0%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.5;
      background: white;
    }

    .triangle3 {
      position: absolute;
      height: 100px;
      right: 40px;
      bottom: calc(100% * -0.06);
      left: 50%;
      transform: skewY(-8deg);
      overflow: hidden;
      transform-origin: 0;
      opacity: 0.4;
      background: #ddd;
    }

    @media screen and (min-width: 1096px) {
      .triangle3 {
        right: 0;
        bottom: 0;
      }
    }

    @media screen and (max-width: 768px) {
      .triangle3 {
        right: 20px;
        bottom: -30px;
        height: 90px;
      }
    }

    @media screen and (max-width: 425px) {
      flex-direction:  column;
      height: 500px;

      .triangle3 {
        right: 0;
        bottom: 60px;
        transform: skewY(-24deg);
        height: 160px;
        background: #fff;
      }

      .triangle1 {
        transform: skewY(-24deg);
      }

      .triangle2 {
        display: none;
      }

      .company-logo {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}
